import React from 'react';
import './Skeleton.css';

const Skeleton = ({ width, height, borderRadius }) => {
  return (
    <div 
      className="skeleton-pulse"
      style={{ 
        width: width || '100%',
        height: height || '100%',
        borderRadius: borderRadius || '4px'
      }}
    />
  );
};

export default Skeleton; 