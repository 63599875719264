import React, { useState, useEffect } from 'react';
import Skeleton from './Skeleton';

const ImageWithFallback = ({ src, alt, className, fluid }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(false);
  const fallbackImage = '/images/placeholder.png'; // Your fallback image

  useEffect(() => {
    setImageLoaded(false);
    setError(false);
  }, [src]);

  const handleLoad = () => {
    setImageLoaded(true);
  };

  const handleError = () => {
    setError(true);
    setImageLoaded(true);
  };

  return (
    <div className="image-container" style={{ position: 'relative' }}>
      {!imageLoaded && (
        <Skeleton 
          height={fluid ? '400px' : '100%'} 
          width="100%" 
          borderRadius="8px"
        />
      )}
      <img
        src={error ? fallbackImage : src}
        alt={alt}
        className={`${className} ${imageLoaded ? 'fade-in' : 'hidden'}`}
        onLoad={handleLoad}
        onError={handleError}
        loading="lazy"
        style={{
          opacity: imageLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out'
        }}
      />
    </div>
  );
};

export default ImageWithFallback; 