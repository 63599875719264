import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import ImageWithFallback from './ImageWithFallback'
import './ImageCarousel.css';

const ImageCarousel = ({ images, mainImage }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Combine main image with additional images
  const allImages = mainImage ? [mainImage, ...images] : images;

  return (
    <Carousel 
      activeIndex={index} 
      onSelect={handleSelect}
      interval={null}
      className="product-carousel"
    >
      {allImages.map((img, idx) => (
        <Carousel.Item key={idx}>
          <ImageWithFallback
            className="d-block w-100"
            src={img}
            alt={`Product image ${idx + 1}`}
            fluid
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarousel; 