import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card } from 'react-bootstrap'
import Rating from './Rating'
import css from './css/Nav.css'

const Product = ({ product }) => {
  return (
    <Card className='my-3 p-3 rounded home-card'>
      <Link to={`/product/${product._id}`}>
        <Card.Img 
          src={product.image}
          variant='top' 
          style={{ width: '100%', height: '250px', objectFit: 'cover' }} 
        />
      </Link>

      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as='div'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as='div'>
          <Rating
            value={product.rating}
            text={`${product.numReviews} reviews`}
          />
        </Card.Text>
        <Link to={`/product/${product._id}`}>
          <Card.Text as='h3'>${product.price}</Card.Text>
        </Link>
      </Card.Body>
      <Button variant="success" className="card-btn" as={Link} to={`/product/${product._id}`}>
        Details
      </Button>
    </Card>
  )
}

export default Product
