import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://defne-15b541c2c443.herokuapp.com/', 
  //baseURL: 'http://localhost:8080/',
  withCredentials: true, // This ensures that cookies are sent
});


export default instance;
