import React from 'react';
import ImageWithFallback from '../ImageWithFallback';
import './product.css';

const ResponsiveImage = ({ src, alt }) => {
  return (
    <div className="responsive-image-wrapper">
      <ImageWithFallback 
        src={src} 
        alt={alt} 
        className="responsive-image"
      />
    </div>
  );
};

export default ResponsiveImage;
